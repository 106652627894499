import * as React from "react";
import { Admin, CustomRoutes, Resource, useStore } from 'react-admin';

import { ArticlesList, ShowArticle, ArticleEdit } from './components/articles'
import { PodcastsList, ShowPodcast, PodcastEdit } from './components/podcasts'
import { VideosList, ShowVideo, VideoEdit } from './components/videos'
import { MediaList, ShowMedia, MediaEdit } from './components/media'
import { CategoriesList, ShowCategory } from './components/categories'
import { OutletsList, OutletEdit, OutletCreate, ShowOutletMedia } from './components/outlets'
import { CreatorsList, ShowCreator, CreatorEdit } from "./components/creators"
import { FeedsList, FeedEdit, ShowFeedMedia } from './components/feeds'
import { AdminUsersList, AdminUsersCreate, AdminUsersEdit } from "./components/adminUsers";
import { CategorizedMediaList, CategorizedMediaShow, CategorziedMediaEdit} from "./components/customCategories"

import { Route } from 'react-router-dom'
import { ResetPassword } from "./components/ResetPassword";
import { RequestReset } from "./components/RequestReset";
import { Login } from "./components/Login";
import { FeedTester } from "./components/FeedTester"

import BusinessIcon from '@mui/icons-material/Business';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import ArticleIcon from '@mui/icons-material/Article';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GroupIcon from '@mui/icons-material/Group';
import CategoryIcon from '@mui/icons-material/Category';
import CollectionsIcon from '@mui/icons-material/Collections';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import CustomLayout from './components/CustomLayout';
import './App.css';

import { AuthProvider } from './authprovider/authprovider'
import optoutAPIDataProvider from "./optoutAPIDataProvider";
import { Setup2FA } from "./components/Setup2FA";

const api_url = process.env.API_URL
const App = () => {
  const [ baseUrl, setBaseUrl ] = useStore('baseUrl', api_url || window.location.origin)
  console.log(`rmd baseUrl ${baseUrl} api_url ${api_url}`)
  const dataProvider = new optoutAPIDataProvider(`${baseUrl}/api`)
  React.useEffect(() => {
    setBaseUrl(baseUrl)
  })
  return (
    <div>
      <Admin
        dataProvider={dataProvider}
        authProvider={new AuthProvider(baseUrl) }
        loginPage={Login}
        layout={CustomLayout}
      >
        <CustomRoutes>
            <Route path="/doReset" element={<ResetPassword />} />
            <Route path="/reset" element={<RequestReset />} />
            <Route path="/2fa" element={<Setup2FA />} />
            <Route path="/feedTester" element={<FeedTester />} />
        </CustomRoutes>
        <Resource
          name="media"
          list={MediaList}
          icon={CollectionsIcon}
          options={{ label: 'All Media' }}
          show={ShowMedia}
          edit={MediaEdit}
        />
        <Resource
          name="articles"
          list={ArticlesList}
          show={ShowArticle}
          icon={ArticleIcon}
          edit={ArticleEdit}
        />
        <Resource
          name="podcasts"
          list={PodcastsList}
          show={ShowPodcast}
          icon={PodcastsIcon}
          edit={PodcastEdit}
        />
        <Resource
          name="videos"
          list={VideosList}
          show={ShowVideo}
          icon={YouTubeIcon}
          edit={VideoEdit}
        />
        <Resource
          name="outlets"
          list={OutletsList}
          edit={OutletEdit}
          create={OutletCreate}
          icon={BusinessIcon}
          show={ShowOutletMedia}
        />
        <Resource
          name="feeds"
          list={FeedsList}
          edit={FeedEdit}
          icon={RssFeedIcon}
          show={ShowFeedMedia}
        />
        <Resource
          name="creators"
          list={CreatorsList}
          show={ShowCreator}
          icon={GroupIcon}
          edit={CreatorEdit}
        />
        <Resource
          name="customCategories"
          list={CategorizedMediaList}
          show={CategorizedMediaShow}
          icon={CategoryIcon}
          edit={CategorziedMediaEdit}
          options={{ label: 'Opt-Out Categorized' }}
        />
        <Resource
          name="categories"
          list={CategoriesList}
          show={ShowCategory}
          icon={CategoryIcon}
          options={{ label: 'RSS Categories' }}
        />
        {permissions => (
          <>
            { permissions.isOwner === 'true'
              ? <Resource name="adminUsers"
                  list={AdminUsersList}
                  create={AdminUsersCreate}
                  edit={AdminUsersEdit}
                  icon={AdminPanelSettingsIcon}
                  options={{ label: 'Admin Users' }}
                />
              : null }
          </>
        )}
      </Admin>
    </div>
  )
}

export default App;

