import React from "react";
import {
  Datagrid,
  List,
  TextField,
  UrlField,
  ImageField,
  Pagination,
  Show,
  ShowButton,
  EditButton,
  Edit,
  SimpleForm,
  SimpleFormIterator,
  ArrayInput,
  TextInput,
  Create,
  RadioButtonGroupInput,
  ReferenceManyField,
  SimpleShowLayout,
  BooleanField,
  SelectInput
} from 'react-admin';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useRecordContext } from "react-admin";
import LinkIconUrlField from "./utility/LinkIconUrlField";
import { ShowImage } from "./utility/ShowImage";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { ClockCircleOutlined } from '@ant-design/icons'
import { MediaTextField } from "./utility/MediaTextField";
import { FeedTextField } from "./utility/FeedTextField";
import optOutCategories from "./utility/optOutCategories";

const Separator = () => <Box pt="1em" />;

const OutletTitle = () => {
    const record = useRecordContext();
    return <span> {record ? `${record.outletName}` : ''}</span>;
};

const OutletFilters = [
  <TextInput source="text" label="Search" alwaysOn />
]

const OutletsPagination = props =>
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const OutletsList = (props) => (
  <List
    filters={OutletFilters}
    perPage={50}
    pagination={<OutletsPagination />}
    sort={{field: 'outletName', order: 'ASC'}}
  >
    <Datagrid
      bulkActionButtons={false}
      rowClick='edit'
    >
      <ImageField source="iconURL" label="" className="thumbnail" sortable={false} />
      <TextField source="outletName" />
      <UrlField source="websiteURL" target="_blank" label="Website" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

const validateOutletCreate = (values) => {
  const errors = {}
  if(!values.outletName) {
    errors.outletName = "The Outlet Name is Required"
  }
  if(!values.websiteURL) {
    errors.websiteURL = "The Website URL is Required"
  }
  if(!values.feedName) {
    errors.feedName = "The Feed Name is Required"
  }
  if(!values.rssURL) {
    errors.rssURL = "The RSS URL is Required"
  }
  return errors
}

const validateOutletEdit = (values) => {
  const errors = {}
  if(!values.outletName) {
    errors.outletName = "The Outlet Name is Required"
  }
  if(!values.websiteURL) {
    errors.websiteURL = "The Website URL is Required"
  }
  return errors
}

export const OutletCreate = () => (
  <Create>
    <SimpleForm validate={validateOutletCreate} sx={{ maxWidth: 800 }}>
      <Typography variant="h6" gutterBottom>
          New Outlet Information
      </Typography>
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="outletName" label="Outlet Name" fullWidth />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="websiteURL" label="Website URL" fullWidth />
          </Box>
      </Box>
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="location.city" label="City" fullWidth />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="location.state" label="State" fullWidth />
          </Box>
          <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="location.region" label="Region" fullWidth />
          </Box>
          <Box flex={1} >
            <TextInput source="location.countryCode" label="Country Code" fullWidth />
          </Box>
      </Box>
      <TextInput source="contactEmail" label="Contact Email" fullWidth />
      <TextInput source="fundingInfoURL" label="Funding Info URL" fullWidth />
      <TextInput source="about" multiline='true' label="About" fullWidth />

      <ArrayInput source="links" label="Outlet Links">
        <SimpleFormIterator inline >
          <TextInput source="url" label="URL" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="outletCategories" label="Outlet Categories">
        <SimpleFormIterator inline>
          <SelectInput source="category" label="Category" choices={optOutCategories} fullWidth />
        </SimpleFormIterator>
      </ArrayInput>

      <Separator />
      <TextInput source="iconURL" label="Icon URL" fullWidth />
      <Separator />

      <Typography variant="h6" gutterBottom>
        New Feed Information
      </Typography>
      <TextInput source="feedName" label="Feed Name" fullWidth />
      <TextInput source="rssURL"  label="RSS URL" fullWidth />
      <RadioButtonGroupInput
        source="format"
        defaultValue='articles'
        label="Format"
        choices={[
          { id: 'articles', name: 'Articles'},
          { id: 'podcasts', name: 'Podcasts'},
          { id: 'videos', name: 'Videos'}
        ]}
      />

      <ArrayInput source="feedLinks" label="Feed Links">
        <SimpleFormIterator>
          <TextInput source="url" label="URL" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="feedCategories" label="Feed Categories">
        <SimpleFormIterator inline>
          <SelectInput source="category" label="Feed Categories" choices={optOutCategories} fullWidth />
        </SimpleFormIterator>
      </ArrayInput>

      <TextInput source="feedIconURL" label="Feed Icon URL" fullWidth />
      <TextInput source="textLogoImageURL" label="Text Logo Image URL" fullWidth />
      <TextInput source="textLogoImageWhiteURL" label="Text Logo Image White URL" fullWidth />
    </SimpleForm>
  </Create>
);

export const OutletEdit = () => (
  <Edit title={<OutletTitle/>}>
    <SimpleForm validate={validateOutletEdit} sx={{ maxWidth: 800 }}>
      <Typography variant="h6" gutterBottom>
        Edit Outlet Information
      </Typography>

      <ImageField source="iconURL" label="" className="largeThumbnail"/>
      <Separator />

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="outletName" label="Outlet Name" fullWidth />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="websiteURL" label="Website URL" fullWidth />
          </Box>
      </Box>
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="location.city" label="City" fullWidth />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="location.state" label="State" fullWidth />
          </Box>
          <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="location.region" label="Region" fullWidth />
          </Box>
          <Box flex={1} >
            <TextInput source="location.countryCode" label="Country Code" fullWidth />
          </Box>
      </Box>
      <TextInput source="contactEmail" label="Contact Email" fullWidth />
      <TextInput source="fundingInfoURL" label="Funding Info URL" fullWidth />
      <TextInput source="about" multiline='true' label="About" fullWidth />

      <ArrayInput source="links" label="Outlet Links">
        <SimpleFormIterator  >
          <TextInput source="url" label="URL" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="outletCategories" label="Outlet Categories">
        <SimpleFormIterator inline>
          <SelectInput source="category" label="Category" choices={optOutCategories} fullWidth />
        </SimpleFormIterator>
      </ArrayInput>

      <Separator />
      <TextInput source="iconURL" label="Icon URL" fullWidth />
      <Separator />

      <Typography variant="h6" gutterBottom>
        Associated Feeds
      </Typography>
      <ReferenceManyField label="Feeds" reference="feeds" target="Outlet">
        <Datagrid bulkActionButtons={false}>
          <TextField source="feedName" fullWidth/>
          <TextField source="format" fullWidth />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
      
      <Separator />
      <Separator />

      <Typography variant="h6" gutterBottom>
        Add New Feed To Outlet
      </Typography>
      <TextInput source="feedName" label="Feed Name" fullWidth />
      <TextInput source="rssURL"  label="RSS URL" fullWidth />
      <RadioButtonGroupInput
        source="format"
        defaultValue='articles'
        label="Format"
        choices={[
          { id: 'articles', name: 'Articles'},
          { id: 'podcasts', name: 'Podcasts'},
          { id: 'videos', name: 'Videos'}
        ]}
      />

      <ArrayInput source="feedLinks" label="Feed Links">
        <SimpleFormIterator>
          <TextInput source="url" label="URL" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="feedCategories" label="Feed Categories">
        <SimpleFormIterator inline>
          <SelectInput source="category" label="Feed Categories" choices={optOutCategories} fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      
      <TextInput source="feedIconURL" label="Feed Icon URL" fullWidth />
      <TextInput source="textLogoImageURL" label="Text Logo Image URL" fullWidth />
      <TextInput source="textLogoImageWhiteURL" label="Text Logo Image White URL" fullWidth />
    </SimpleForm>
  </Edit>
);



export const ShowOutletMedia = () => (
  <Show title={<OutletTitle/>}>
    <SimpleShowLayout>
      <Typography variant="h6" gutterBottom>
         Media
      </Typography>
        <ReferenceManyField label="" reference="media" target="Outlet">
          <Datagrid bulkActionButtons={false}>
            <BooleanField
              source="active"
              label="Active"
              TrueIcon={DoneOutlineIcon}
              FalseIcon={ClockCircleOutlined}
              sx={{ color: 'green' }}
              sortable={false}
            />
            <BooleanField
              source="carousel"
              label="Featured"
              TrueIcon={DoneOutlineIcon}
              FalseIcon={ClockCircleOutlined}
              sx={{ color: 'blue' }}
              sortable={false}
            />
            <ShowImage />
            <MediaTextField source="title" label="Title (Click to Edit)" sortable={false} />
            <FeedTextField label='Feed' />
            <TextField source="Feed.format" label="Format" sortable={false} />
            <TextField source="publicationDateParsed" label="Published" sortable={false} />
            <LinkIconUrlField source="url" label="Link" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)

