import * as React from "react";
import { useRecordContext } from 'react-admin';
import { Link } from '@mui/material';
import RssFeedIcon from '@mui/icons-material/RssFeed';

const RssIconUrlField = ({ source }) => {
  const record = useRecordContext();
  return record ? (
    <Link href={record[source]} target="_blank" sx={{ textDecoration: 'none' }} sortable={false} >
      <RssFeedIcon sx={{ width: '0.8em', height: '0.8em', paddingLeft: 2 }} />
    </Link>
  ) : null;
}

export default RssIconUrlField;