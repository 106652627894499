import * as React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@mui/material/Typography';
import { useMediaQuery, Box } from '@mui/material'
import CustomAppBarMenu from './CustomAppBarMenu';


const CustomAppBar = (props) => { 
  const isLargeEnough = useMediaQuery(theme =>
    theme.breakpoints.up('sm')
  );
  let center = {
    position: 'absolute', 
    left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
  return (
    <AppBar
        sx={{
            "& .RaAppBar-title": {
                flex: 1,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
            },
        }}
        userMenu={<CustomAppBarMenu />}
        {...props}
    >
        <Typography
            variant="h6"
            color="inherit" 
            id="react-admin-title"
        />
        { isLargeEnough &&
          <div style={center}>
            <img 
              src="https://images.optout.news/LOGOS/optout-text.png" 
              className="appBarLogo" alt="Optout"
            /> 
          </div>
        }          
        {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />} 
    </AppBar>
  );
}
export default CustomAppBar;