import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Pagination,
  ReferenceManyField,
  TextInput,
  Show,
  SimpleShowLayout,
  ShowButton,
  BooleanField 
} from 'react-admin';
import {  useRecordContext } from "react-admin";
import LinkIconUrlField from './utility/LinkIconUrlField'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ShowImage } from "./utility/ShowImage";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { ClockCircleOutlined } from '@ant-design/icons'
import { MediaTextField } from "./utility/MediaTextField";
import { FeedTextField } from "./utility/FeedTextField";

const Separator = () => <Box pt="1em" />;

const categoryFilters = [
  <TextInput source="categories.name" label="Search" resettable alwaysOn />
]

const CategoriesPagination = props =>
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const CategoriesList = () => (
  <List
    filters={categoryFilters}
    filterDefaultValues={{"categories.name": ""}}
    pagination={<CategoriesPagination/>}
    perPage={50}
  >
    <Datagrid rowClick="show">
      <TextField source="name" label="Category" />
      <ShowButton />
    </Datagrid>
  </List>
)

const CategoryTitle = () => {
  const record = useRecordContext()
  return <span> {record ? `${record.name}` : ''}</span>;
}


export const ShowCategory = () => {
  return (
    <Show title={<CategoryTitle />}>
      <SimpleShowLayout>
        <Typography variant="h7" gutterBottom>
          Articles
        </Typography>
        <ReferenceManyField 
          label="" 
          target="categories.name" 
          reference="articles" 
          sort={{field: 'publicationDateISO', order: 'DESC'}}
        >
          <Datagrid linkType="show" bulkActionButtons={false}>
            <BooleanField
              source="active"
              label="Active"
              TrueIcon={DoneOutlineIcon}
              FalseIcon={ClockCircleOutlined}
              sx={{ color: 'green' }}
              sortable={false}
            />
            <BooleanField
              source="carousel"
              label="Featured"
              TrueIcon={DoneOutlineIcon}
              FalseIcon={ClockCircleOutlined}
              sx={{ color: 'blue' }}
              sortable={false}
            />
            <ShowImage />
            <MediaTextField source="title" label="Title (Click to Edit)" sortable={false} />
            <FeedTextField label="Feed" />
            <TextField label="Published" source="publicationDateParsed"/>
            <LinkIconUrlField source="url" label="Link"  />
          </Datagrid>
        </ReferenceManyField>
        <Separator />

        <Typography variant="h7" gutterBottom>
          Podcasts
        </Typography>
        <ReferenceManyField 
          label="" 
          target="categories.name" 
          reference="podcasts" 
          sort={{field: 'publicationDateISO', order: 'DESC'}}
        >
          <Datagrid linkType="show" sort={{publicationDateISO: -1}} bulkActionButtons={false}>
            <BooleanField
              source="active"
              label="Active"
              TrueIcon={DoneOutlineIcon}
              FalseIcon={ClockCircleOutlined}
              sx={{ color: 'green' }}
              sortable={false}
            />
            <BooleanField
              source="carousel"
              label="Featured"
              TrueIcon={DoneOutlineIcon}
              FalseIcon={ClockCircleOutlined}
              sx={{ color: 'blue' }}
              sortable={false}
            />
            <ShowImage />
            <MediaTextField source="title" label="Title (Click to Edit)" sortable={false} />
            <FeedTextField label="Feed" />
            <TextField label="Published" source="publicationDateParsed"/>
            <LinkIconUrlField source="url" label="Link"  />
          </Datagrid>
        </ReferenceManyField>
        <Separator/>
        
        <Typography variant="h7" gutterBottom>
          Videos 
        </Typography>
        <ReferenceManyField 
          label="" 
          target="categories.name" 
          reference="videos" 
          sort={{field: 'publicationDateISO', order: 'DESC'}}
        >
          <Datagrid linkType="show" sort={{publicationDateISO: -1}} bulkActionButtons={false}>
            <BooleanField
              source="active"
              label="Active"
              TrueIcon={DoneOutlineIcon}
              FalseIcon={ClockCircleOutlined}
              sx={{ color: 'green' }}
              sortable={false}
            />
            <BooleanField
              source="carousel"
              label="Featured"
              TrueIcon={DoneOutlineIcon}
              FalseIcon={ClockCircleOutlined}
              sx={{ color: 'blue' }}
              sortable={false}
            />
            <ShowImage />
            <MediaTextField source="title" label="Title (Click to Edit)" sortable={false} />
            <FeedTextField label="Feed" />
            <TextField label="Published" source="publicationDateParsed"/>
            <LinkIconUrlField source="url" label="Link"  />
          </Datagrid>
        </ReferenceManyField>

      </SimpleShowLayout>
    </Show>
    )
  }



  