import React, { useState } from 'react'
import { useStore } from 'react-admin'
import { FormGroup, Card, CardContent, TextField, Button } from '@mui/material';

export const FeedTester = () => {

  const [ URL, setURL ] = useState("")
  const [ parsedFeed, setParsedFeed ] = useState([])
  const [ error, setError ] = useState(undefined)
  const [ baseURL ] = useStore('baseUrl', process.env.API_URL || 'http://localhost:80')


  const handleSubmit = async (e) => {
    e.preventDefault()
    const url = `${baseURL}/api/feedTester`
    try {
      const response = await fetch( url, {
        method: 'POST',
        body: `{"feedURL": "${URL}"}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (response.status <= 300) {
        const result = await response.json()
        if(!result) return
        setParsedFeed(result)
      } else {
        const result = await response.text()
        setError(result)
      }
    } catch (err) {
      console.error(err)
      setError(err)
    }
  }

  const handleURL = (e) => {
    setURL(e.target.value)
    return true
  }

  if (error) {
    return (
      <div>
        <Card>
          <CardContent>
            <span>
              Failed to fetch feed: {error}
            </span>
          </CardContent>
        </Card>
      </div>
    )
  } else {
    const feedItems = parsedFeed.map(item =>
      <li key={item.guid}>
        <br/>
        <span>Title : {item.title}</span> <br/>
        <span>Link : {item.link}</span> <br/>
        <span>Podcast link : {item.podcastMediaLink}</span> <br/>
        <span>PubDate : {item.pubDate}</span> <br/>
        <span>Author : {item.author}</span> <br/>
        <span>guid : {item.guid}</span> <br/>
        <span>IsoDate  {item.isoDate}</span> <br/>
        <span>Summary : {item.description}</span> <br/>
        <br/>
      </li>
    )

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <FormGroup sx={{flex: 1, width: '100%'}}>
            <Card>
              <CardContent>
                <TextField
                  sx={{width: '50%'}}
                  label="URL"
                  type='URL'
                  id='URL'
                  value={URL}
                  onChange={handleURL}
                />
                <br></br><br></br>
                <Button variant="contained" type='submit'>
                  Test Feed
                </Button>
              </CardContent>
            </Card>
          </FormGroup>
        </form>
        {feedItems}
      </div>
    )
  }
}