
import React from "react";
import { 
  Datagrid, 
  List,
  Edit,
  TextInput, 
  TextField,
  ArrayField,
  Pagination,
  useRecordContext,
  Show,
  SimpleShowLayout,
  SimpleForm,
  EditButton,
  ImageField,
  ArrayInput,
  SimpleFormIterator,
  BooleanField
} from 'react-admin';
import LinkIconUrlField from "./utility/LinkIconUrlField";
import Typography from '@mui/material/Typography';
import { FeedTextField } from "./utility/FeedTextField";
import { MediaTextField } from "./utility/MediaTextField";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { ClockCircleOutlined } from '@ant-design/icons'

const CreatorsPagination = props => 
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const CreatorTitle = () => {
  const record = useRecordContext()
  return <span> {record ? `${record.name}` : ''} </span>;
}

const CreatorFilters = [
  <TextInput source="text" label="Search" alwaysOn />
]

export const CreatorsList = () => ( 
  <List 
    perPage={50}
    pagination={<CreatorsPagination />} 
    sort={{field: 'name', order: 'ASC'}}
    filters={CreatorFilters}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
)


export const ShowCreator = () => {
  return (
    <Show title={<CreatorTitle/>} >
      <SimpleShowLayout>
        <TextField source="name" sx={{ fontSize: 24 }}/>
        <ArrayField source="media" >
            <Datagrid 
              bulkActionButtons={false}
            >
              <BooleanField
                  source="active"
                  label="Active"
                  TrueIcon={DoneOutlineIcon}
                  FalseIcon={ClockCircleOutlined}
                  sx={{ color: 'green' }}
                  sortable={false}
              />
              <BooleanField
                source="carousel"
                label="Featured"
                TrueIcon={DoneOutlineIcon}
                FalseIcon={ClockCircleOutlined}
                sx={{ color: 'blue' }}
                sortable={false}
              />
              <ImageField source="imageUrl" label="" className="thumbnail" sortable={false}/>
              <MediaTextField source="title" label="Title (Click to Edit)" sortable={false} />
              <TextField source="format" />
              <FeedTextField label='Feed' />
              <TextField source="publicationDateParsed" label="Published"  />               
              <LinkIconUrlField source="url" label="Link" sortable={false} />
            </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  )
}

const CreatorName = () => {
  const record = useRecordContext();
  return <span> {record ? `${record.name}` : ''} </span>
};

export const CreatorEdit = () => {
  return (
   <Edit title={<CreatorName/>}>
    <SimpleForm  sx={{ maxWidth: 800 }}>
        <TextInput source='name' label='Name' fullWidth /> 
        <TextInput source='bio' label='Bio' fullWidth />
        <ArrayInput source="creatorLinks" label="Links">
          <SimpleFormIterator  >
            <TextInput source="url" label="URL" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source='creatorImageUrl' label='Image URL' fullWidth />
        <Typography variant="h7" gutterBottom>
         Bio Picture
        </Typography>
        <ImageField source='creatorImageUrl' label='Image' className="largeThumbnail"/>
      </SimpleForm> 
   </Edit>
  )
}

// <TextField source="publicationDateISO" label="Published (ISO)" sx={{ fontSize: 12 }} />
