import { TextField, useRecordContext, useRedirect } from "react-admin";

export const FeedTextField = props => {
  const record = useRecordContext()
  const redirect = useRedirect()
  return (
    <TextField 
      source="Feed.feedName"
      label="Feed" 
      sx={{ 
        fontStyle: 'italic', 
        textDecorationLine: 'underline',
        cursor: 'pointer' 
        }} 
      sortable={false}
      onClick={handleClickFeed.bind(this, record, redirect)}
    />
  )
}

const handleClickFeed = (item, redirect) => {
  const feed = item?.Feed
  if (!feed) return
  redirect?.('edit', 'feeds', feed._id)
}
