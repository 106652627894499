import React, { useEffect, useRef, useState } from 'react'
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Collapse,
  FormGroup,
  TextField,
} from '@mui/material';
import QRCodeStyling from "qr-code-styling";
import { useRedirect, useStore } from 'react-admin';

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  image: "/favicon.ico",
  dotsOptions: {
    gradient: {
      type: 'radial',
      colorStops: [
        { offset: 0, color: '#EC503D' },
        { offset: 0.6, color: '#333' }
      ]
    },
    type: "classy-rounded"
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 10
  }
})

export const Setup2FA = (props) => {
  const [ baseURL ] = useStore('baseUrl', process.env.API_URL || 'http://localhost:80')
  let [ stage, setStage ] = useState(0)
  let [ password, setPassword ] = useState('')
  let [ alerting, setAlerting ] = useState(false)
  let [ secret, setSecret ] = useState('')
  let [ email, setEmail ] = useState('')
  let [ totp, setTotp ] = useState('')
  const ref = useRef(null)
  const redirect = useRedirect()

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  const handleSubmitPassword = async (e) => {
    try {
      const response = await fetch(`${baseURL}/auth/setup2FA.json`, {
        method: 'POST',
        body: `{"password": "${password}"}`,
        headers: {
          'Content-Type': 'application/json',
          'jwt': localStorage.getItem('jwt')
        },
      })
      if (response.status >= 300) {
        const text = await response.text
        throw new Error(text)
      }
      const json = await response.json()
      setSecret(json.secret)
      setEmail(json.email)
      if (json.alreadyHas2fa) {
        setAlerting(true)
        setStage(1)
      } else {
        setStage(2)
      }
    } catch (e) {
      console.error(`Failed to advance state: ${e}`)
    }
  }

  const handleSubmitCode = async (e) => {
    try {
      const response = await fetch(`${baseURL}/auth/verifyInitial2FA.json`, {
        method: 'POST',
        body: `{"code": "${document.getElementById('code')?.value}"}`,
        headers: {
          'Content-Type': 'application/json',
          'jwt': localStorage.getItem('jwt')
        },
      })
      if (response.status >= 300) {
        const text = await response.text
        throw new Error(text)
      }
      redirect('/')
    } catch (e) {
      console.error(`Failed to advance state: ${e}`)
    }
  }

  const handlePassword = (e) => {
    setPassword(e.target.value)
    return true
  }

  const handleTOTP = (e) => {
    setTotp(e.target.value)
    return true
  }

  // Stage 0 - verify password
  // Stage 1 - if user already has 2fa set up warn them
  // Stage 2 - display QR code and ask for generated code
  // Stage 3 - verify initial code and confirm
  let mainContent
  switch (stage) {
    case 0:
      mainContent = (
        <CardContent className='loginContent' sx={{backgroundColor: '#ffffffee'}}>
          <TextField
            label="Verify Password"
            type="password"
            id="password"
            value={password}
            onChange={handlePassword}
          />
          <Button
            type='submit'
            variant='outlined'
            onClick={handleSubmitPassword}>
              Verify
          </Button>
        </CardContent>
      )
      break;
    case 1:
      mainContent = (
        <Collapse in={alerting}>
          <Alert
            severity='warning'
            onClose={() => {
              setAlerting(false)
              setStage(2)
            }}
          >
            <AlertTitle>Two factor already set up</AlertTitle>
            If you continue, existing authentication methods will stop working.
            Close this alert to continue setup, or hit back to stick with your existing two factor setup.
          </Alert>
        </Collapse>
      )
      break

    case 2:
      qrCode.update({
        data: `otpauth://totp/Optout%20Media%20Foundation:${email}?secret=${secret}&issuer=Optout%20Media%20Foundation`
      })
      mainContent  = (
        <CardContent className='loginContent' sx={{backgroundColor: '#ffffffee'}}>
          <FormGroup>
          <TextField label="Enter code from generator" id="code" value={totp} onChange={handleTOTP} />
            <Button
              type='submit'
              variant='outlined'
              onClick={handleSubmitCode}>
                Verify Code
            </Button>
          </FormGroup>
        </CardContent>
      )
      break
    default:
      break;
  }

  return (
    <div className='loginPage'>
        <FormGroup>
          <Card className='login' sx={{backgroundColor: '#00000000'}}>
            <div ref={ref}></div>
            {mainContent}
          </Card>
        </FormGroup>
    </div>
  )
}