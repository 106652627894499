import React from "react";
import { 
  Datagrid, 
  List, 
  TextField, 
  ReferenceField,
  ReferenceManyField,
  ImageField,
  Pagination,
  Show,
  SimpleShowLayout,
  Edit,
  EditButton,
  ShowButton,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanField,
  TopToolbar,
  SelectInput
} from 'react-admin';
import RssIconUrlField from "./utility/RssIconUrlField";
import { useRecordContext } from "react-admin";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ShowImage } from './utility/ShowImage'
import LinkIconUrlField from './utility/LinkIconUrlField'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { ClockCircleOutlined } from '@ant-design/icons'
import { MediaTextField } from "./utility/MediaTextField";
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { Button } from '@mui/material';
import optOutCategories from "./utility/optOutCategories";

const FeedTitle = () => {
    const record = useRecordContext();
    return <span> {record ? `${record.feedName}` : ''} </span>;
};

const FeedFilters = [
  <TextInput source="text" label="Search" alwaysOn />
]

const FeedTesterButton = () => (
  <Button 
    sx={{ color:'white', backgroundColor: 'orange', borderColor: 'green' }}
  >
    <Link component={RouterLink} to="/feedTester">
      Feed Tester
    </Link>
  </Button>
)

const ListActions = () => (
  <TopToolbar>
     <FeedTesterButton />
  </TopToolbar>
)

const Separator = () => <Box pt="1em" />;

const FeedsPagination = props => 
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const validateFeedEdit = (values) => {
  const errors = {}
  if(!values.feedName) {
    errors.feedName = "The Feed Name is Required"
  }
  if(!values.rssURL) {
    errors.rssURL = "The RSS URL is Required"
  }
  return errors
}

export const FeedsList = (props) => (
  <List 
    filters={FeedFilters}
    perPage={50} 
    pagination={<FeedsPagination/>} 
    sort={{field: 'feedName', order: 'ASC'}}
    actions={<ListActions/>}
  >
    <Datagrid bulkActionButtons={false}> 
      <ImageField source="feedIconURL" label="" className="thumbnail" sortable={false} /> 
      <TextField source="feedName" label="Feed Name" />  
      <ReferenceField source="Outlet" reference="outlets">
        <TextField source="outletName" />
      </ReferenceField>
      <RssIconUrlField source="rssURL" label="RSS" sortable={false} />
      <TextField source="format" label="Format" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

export const FeedEdit = () => (
  <Edit title={<FeedTitle/>}>
      <SimpleForm validate={validateFeedEdit} sx={{ maxWidth: 800 }}>
        <Typography variant="h6" gutterBottom>
          Edit Feed Information 
        </Typography>
        <ImageField source="feedIconURL" label="" className="largeThumbnail"/>
        <Separator />
        <Typography variant="h7" gutterBottom>
         Outlet
        </Typography>
        <ReferenceField source="Outlet" reference="outlets">
          <TextField source="outletName" />
        </ReferenceField>
        <Separator />
        <TextInput source="feedName" label="Feed Name"  fullWidth />
        <TextInput source="rssURL" label="RSS URL" fullWidth />
        <TextInput source="feedAbout" multiline='true' label="About" fullWidth />
        <RadioButtonGroupInput 
          source="format" 
          label="Format"  
          choices={[
            { id: 'articles', name: 'Articles'},
            { id: 'podcasts', name: 'Podcasts'},
            { id: 'videos', name: 'Videos'}
          ]}
        />
        <ArrayInput source="feedLinks" label="Feed Links">
          <SimpleFormIterator>
            <TextInput source="url" label="URL" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="feedCategories" label="Feed Categories">
          <SimpleFormIterator inline>
            <SelectInput source="category" label="Category" choices={optOutCategories} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="feedIconURL" label="Feed Icon URL" fullWidth /> 
        <TextInput source="textLogoImageURL" label="Text Logo Image URL" fullWidth /> 
        <TextInput source="textLogoImageWhiteURL" label="Text Logo Image White URL" fullWidth /> 
      </SimpleForm>
  </Edit>
);


export const ShowFeedMedia = () => (
  <Show title={<FeedTitle/>}>
    <SimpleShowLayout>
      <Typography variant="h6" gutterBottom>
         Media
      </Typography>
        <ReferenceManyField label="" reference="media" target="Feed">
          <Datagrid bulkActionButtons={false}>
            <BooleanField
              source="active"
              label="Active"
              TrueIcon={DoneOutlineIcon}
              FalseIcon={ClockCircleOutlined}
              sx={{ color: 'green' }}
              sortable={false}
            />
            <BooleanField
              source="carousel"
              label="Featured"
              TrueIcon={DoneOutlineIcon}
              FalseIcon={ClockCircleOutlined}
              sx={{ color: 'blue' }}
              sortable={false}
            />
            <ShowImage />
            <MediaTextField source="title" label="Title (Click to Edit)" sortable={false} />
            <TextField source="Feed.format" label="Format" sortable={false} />
            <TextField source="publicationDateParsed" label="Published" sortable={false} />
            <LinkIconUrlField source="url" label="Link" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
    </SimpleShowLayout>
 </Show>
)
