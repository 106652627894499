import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const httpClient = fetchUtils.fetchJson;

class optoutAPIDataProvider {
    constructor(base) {
        this.baseURL = base
    }

    authenticationHeader = () => {
        return new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'jwt': localStorage.getItem('jwt')
        })
    }

    getList = (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        let filter = params.filter
        if (resource === 'categories') {
            filter['title'] = undefined
            filter['categories.name'] = filter?.categories?.name
            filter.categories = undefined
            if (filter['categories.name'] === '') {
                filter['categories.name'] = undefined
            }
        }
        if (filter.text) {
            filter = { "$text": { "$search": filter.text }}
        }
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(filter),
        };
        const countUrl = `${this.baseURL}/${resource}/count?${stringify(query)}`
        var count = 0
        return httpClient(countUrl, {
            headers: this.authenticationHeader(),
            method: 'GET',
        }).then(({ headers, json }) => {
            count = json.count
            const url = `${this.baseURL}/${resource}?${stringify(query)}`;
            return httpClient(url, {
                headers: this.authenticationHeader(),
                method: 'GET',
            })
        }).then(({ headers, json }) => {
            return Promise.resolve({
                data: json.map(item => {
                    item.id = item._id
                    return item
                }),
                total: count,
            })
        }).catch(e => {
            console.log(e)
            return Promise.reject(e)
        })
    }

    getOne = (resource, params) => {
        return httpClient(`${this.baseURL}/${resource}/${params.id}`, {
            headers: this.authenticationHeader()
        }).then(({ json }) => {
          return {
            data: { ...json, id: json._id }
          }
        })
    }

    getMany = (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${this.baseURL}/${resource}/multi?${stringify(query)}`;
        return httpClient(url, {
            headers: this.authenticationHeader()
        }).then(({ json }) => ({
            data: json.map(resource => ({ ...resource, id: resource._id }) ),
        }));
    }

    getManyReference = (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${this.baseURL}/${resource}/multi?${stringify(query)}`;

        return httpClient(url, {
            headers: this.authenticationHeader()
        }).then(({ headers, json }) => ({
            data: json.map(resource => ({ ...resource, id: resource._id }) ),
            total: parseInt(headers.get('content-range')?.split('/').pop(), 10),
        }));
    }

    create = (resource, params) =>
        httpClient(`${this.baseURL}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json._id },
            headers: this.authenticationHeader(),
        }))

    update = (resource, params) =>
        httpClient(`${this.baseURL}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
            headers: this.authenticationHeader(),
        }).then(({ json }) => ({
           data: { ...json, id: json._id }
        }))

    updateMany = (resource, params) => {
        const query = {
            filter: JSON.stringify({ _id: { "$in": params.ids } }),
        };
        return httpClient(`${this.baseURL}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
            headers: this.authenticationHeader(),
        }).then(({ json }) => ({ data: json }));
    }

    delete = (resource, params) =>
        httpClient(`${this.baseURL}/${resource}/${params.id}`, {
            method: 'DELETE',
            headers: this.authenticationHeader(),
        }).then(({ json }) => ({
            data: { ...json, id: json._id }
        }))

    deleteMany = (resource, params) => {
        const query = {
            filter: JSON.stringify({ _id: { "$in": params.ids } }),
        };
        return httpClient(`${this.baseURL}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            headers: this.authenticationHeader(),
        }).then(({ json }) => ({ data: json }));
    }
};

export default optoutAPIDataProvider
