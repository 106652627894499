import { BulkUpdateButton } from "react-admin";
import { AddCircle, ControlPointDuplicate, DoDisturbOn, HideSource, NotificationsActive } from '@mui/icons-material'



export const MakeActiveButton = () => (
  <BulkUpdateButton
    label="Active"
    icon={<AddCircle />}
    data={{ active: true }}
  />
);

// Making featured also makes active, if not already active
export const MakeFeaturedButton = () => (
  <BulkUpdateButton
    label="Feature"
    icon={<ControlPointDuplicate />}
    data={{ carousel: true, active: true }}
  />
);

// Deactivating will also unfeature
export const DeactivateButton = () => (
  <BulkUpdateButton
    label="Inactive"
    icon={<DoDisturbOn />}
    data={{ active: false, carousel: false }}
  />
);

export const UnfeatureButton = () => (
  <BulkUpdateButton
    label="Unfeature"
    icon={<HideSource />}
    data={{ carousel: false }}
  />
);

export const NotifyButton = () => (
  <BulkUpdateButton
    label="Notification"
    icon={<NotificationsActive />}
    data={{ notify: true, needsNotify: 0 }}
  />
);

// export const NotifyButton = () => (
//   <BulkUpdateFormButton>
//     <SimpleForm>
//       <SelectInput 
//         label="Choose Topic"
//         source="notifyTopic"  
//         choices={notifyTopicChoices} 
//       />
//     </SimpleForm>
//   </BulkUpdateFormButton>
// );