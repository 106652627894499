import { useEditContext, useRecordContext, useRedirect, ChipField } from "react-admin";

export const CustomCategoryChip = props => {
  const record = useRecordContext()
  const redirect = useRedirect()
  const medium = useEditContext().record
  const onDelete = 
    props.deleteCategory ? {onDelete: () => props.deleteCategory(record, medium)}
    : null

    return (
      <ChipField 
        source="name" 
        onClick={() => redirect('list', 'customCategories')} 
        {...props} 
        {...onDelete}
      />
    )
}