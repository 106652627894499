import React, {Fragment } from "react";
import {
  ArrayInput,
  Datagrid,
  List,
  TextField,
  TextInput,
  Pagination,
  ArrayField,
  SingleFieldList,
  BooleanField,
  Show,
  SimpleShowLayout,
  ShowButton,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  BooleanInput,
  ReferenceArrayField,
  ChipField,
  SelectInput
} from 'react-admin';
import LinkIconUrlField from "./utility/LinkIconUrlField";
import { QuickFilter }  from "./utility/Quickfilter";
import {
  MakeActiveButton,
  DeactivateButton,
  MakeFeaturedButton,
  UnfeatureButton
} from "./utility/BulkActiveFeatured";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { ClockCircleOutlined } from '@ant-design/icons'
// import { OutletTextField } from "./OutletTextField";
import { FeedTextField } from "./utility/FeedTextField";
import { ShowImage } from "./utility/ShowImage";
import Typography from '@mui/material/Typography';
import { CategoryChip } from "./utility/categoryChip";
import { CustomCategoryChip } from "./utility/customCategoryChip";
import optOutCategories from "./utility/optOutCategories";



const videoFilters = [
  <TextInput source="text" label="Search" alwaysOn />,
  <QuickFilter source="active" label="Active" defaultValue={true} />,
  <QuickFilter source="carousel" label="Featured" defaultValue={true} />
]

const VideoBulkActionButtons = props => (
  <Fragment>
      <MakeActiveButton />
      <DeactivateButton />
      <MakeFeaturedButton />
      <UnfeatureButton />
  </Fragment>
);

const VideosPagination = props =>
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const VideosList = () => (
  <List
    filters={videoFilters}
    pagination={<VideosPagination/>}
    perPage={50}
    sort={{field: 'publicationDateISO', order: 'DESC'}}
  >
    <Datagrid
      sort={{ field: 'publicationDateISO', order: 'DESC'}}
      bulkActionButtons={<VideoBulkActionButtons/>}
    >
      <BooleanField
        source="active"
        label="Active"
        TrueIcon={DoneOutlineIcon}
        FalseIcon={ClockCircleOutlined}
        sx={{ color: 'green' }}
        sortable={false}
      />
      <BooleanField
        source="carousel"
        label="Featured"
        TrueIcon={DoneOutlineIcon}
        FalseIcon={ClockCircleOutlined}
        sx={{ color: 'blue' }}
        sortable={false}
      />
      <BooleanField
        source="notify"
        label="Notification"
        TrueIcon={DoneOutlineIcon}
        FalseIcon={ClockCircleOutlined}
        sx={{ color: 'blue' }}
        sortable={false}
      />
      <ShowImage />
      <TextField source="title" sortable={false} />
      <FeedTextField label="Feed" />
      <TextField source="publicationDateParsed" label="Published" sortable={false} />
      <EditButton />
      <ShowButton />
      <LinkIconUrlField source="url" label="Link" sortable={false} />
    </Datagrid>
  </List>
)

export const ShowVideo = () => (
  <Show title="Video">
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="title" />
      <TextField source="Feed.Outlet.outletName" label="Outlet" />
      <TextField source="publicationDateParsed" label="Published" sortable={false} />
      <TextField source="publicationDateISO" label="Published (ISO)" />
      <ReferenceArrayField label="Creators" reference="creators" source="CreatorIDs">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ArrayField source="customCategories" label="Opt-Out Categories">
        <SingleFieldList linkType={false}>
          <CustomCategoryChip />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="categories" label="RSS Categories">
        <SingleFieldList linkType={false}>
          <CategoryChip />
        </SingleFieldList>
      </ArrayField>
      <LinkIconUrlField source="url" label="Link" sortable={false} />
    </SimpleShowLayout>
  </Show>
)

const validateVideoEdit = (values) => {
  const errors = {}
  if(!values.active && values.carousel) {
    errors.active = "Error: Video must be active to be featured"
  }
  return errors
}

export const VideoEdit = () => (
  <Edit title="Video">
    <SimpleForm validate={validateVideoEdit} sx={{ maxWidth: 800 }}>
      <Typography variant="h6" gutterBottom>
        Edit Video Information
      </Typography>
      <TextInput source="title" fullWidth />
      <TextInput source="publicationDateParsed" label="Published" fullWidth/>
      <TextInput source="publicationDateISO" label="Published (ISO)" fullWidth/>
      <BooleanInput source="active" label="Active" />
      <BooleanInput source="carousel" label="Featured"  />
      <ArrayInput source="customCategories" label="Opt-Out Categories">
        <SimpleFormIterator inline >
          <SelectInput source="name" label="Opt-Out Category" choices={optOutCategories} />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="categories" label="RSS Categories">
          <SimpleFormIterator inline disableAdd>
            <TextInput source="name" label="RSS Category" />
          </SimpleFormIterator>
        </ArrayInput>
    </SimpleForm>
  </Edit>
)