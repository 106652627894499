import { UserMenu, useLogout, useRedirect } from 'react-admin'
import { MenuItem } from '@mui/material'
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import HttpsIcon from '@mui/icons-material/Https';

const CustomAppBarMenu = (props) => {
  const logout = useLogout();
  const handleLogout = () => logout();
  const redirect = useRedirect()
  const has2fa = localStorage.getItem('has2fa')
  const twoFA = (has2fa === true || has2fa === 'true') ? null : (
    <MenuItem
        onClick={() => redirect('/2fa')}
      >
        <HttpsIcon /> Setup Two-Factor authentication
    </MenuItem>
  )

  return (
    <UserMenu>
      <MenuItem
        onClick={handleLogout}
      >
        <ExitIcon /> Logout
      </MenuItem>
      {twoFA}
    </UserMenu>
  )
}

export default CustomAppBarMenu