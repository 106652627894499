
// const authProvider = {
//   // authentication
//   login: params => Promise.resolve(/* ... */),
//   checkError: error => Promise.resolve(/* ... */),
//   checkAuth: params => Promise.resolve(/* ... */),
//   logout: () => Promise.resolve(/* ... */),
//   getIdentity: () => Promise.resolve(/* ... */),
//   // authorization
//   getPermissions: () => Promise.resolve(/* ... */),
// };

export class AuthProvider {
  constructor(baseUrl) {
    this.baseURL = baseUrl
  }

  async login({ username, password }) {
    const request = {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: `{"username": "${username}","password": "${password}"}`
    }
    const response = await fetch(`${this.baseURL}/auth/login.json`, request)
    if (response.status > 299) {
      const result = await response.json()
      if (result.error === 'totp_required') {
        return Promise.reject(new Error(result.error))
      }
      return Promise.reject(new Error(`Invalid email or password: ${JSON.stringify(result)}`))
    }
    const data = await response.json()
    const {firstName, lastName, token, expiresAt, isOwner, has2fa} = data
    localStorage.setItem('email', username)
    localStorage.setItem('jwt', token)
    localStorage.setItem('name', `${firstName} ${lastName}`)
    localStorage.setItem('expires', expiresAt)
    localStorage.setItem('isOwner', isOwner)
    localStorage.setItem('has2fa', has2fa)
  }

  async checkAuth(params) {
     if (localStorage.getItem('jwt') === null) return Promise.reject('No token')
     let expiry = localStorage.getItem('expires')
     return Date.now() < expiry ? Promise.resolve() : Promise.reject('expired')
  }

  async checkError(error) {
    if (error.status === 401 || error.status === 403) {
      return Promise.reject()
    } else {
      return Promise.resolve()
    }
  }

  async logout() {
    const request = {
      method: 'POST',
      body: JSON.stringify({
        jwt: this.jwt
      })
    }
    localStorage.removeItem('jwt')
    fetch(`${this.baseURL}/auth/logout.json`, request).then(response => {
      return Promise.resolve()
    })
  }

  async getIdentity() {
    try {
      return Promise.resolve({
        id: localStorage.getItem('jwt'),
        fullName: localStorage.getItem('name'),
      })
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getPermissions() {
    return Promise.resolve({isOwner: localStorage.getItem('isOwner')})
  }
}
