import { ImageField, useRecordContext } from "react-admin";

export const ShowImage = () => {
  const record = useRecordContext()
  if(!record.imageUrl) {
    return <ImageField source="Feed.feedIconURL" label="" className="thumbnail" sortable={false} />
  } else {
    return <ImageField source="imageUrl" label="" className="thumbnail" sortable={false} />
  }
}
