
const optOutCategories = [
  { id: 'Arts and Culture', name: 'Arts and Culture' },
  { id: 'Business and Economics', name: 'Business and Economics' },
  { id: 'Business', name: 'Business'},
  { id: 'Economics', name: 'Economics'},
  { id: 'Climate', name: 'Climate' },
  { id: 'Criminal Justice', name: 'Criminal Justice'},
  { id: 'Education', name: 'Education'},
  { id: 'Extremism', name: 'Extremism'},
  { id: 'Health', name: 'Health'},
  { id: 'Immigration', name: 'Immigration'},
  { id: 'Labor Organizing', name: 'Labor Organizing'},
  { id: 'Labor', name: 'Labor'},
  { id: 'LGBTQ+', name: 'LGBTQ+'},
  { id: 'Media', name: 'Media'},
  { id: 'Racial Justice', name: 'Racial Justice'},
  { id: 'Science', name: 'Science'},
  { id: 'Sports', name: 'Sports'},
  { id: 'Tech', name: 'Tech'},
  { id: 'U.S. Politics', name: 'U.S. Politics'},
  { id: 'Politics', name: 'Politics'},
  { id: 'Geopolitics', name: 'Geopolitics'},
  { id: "Women's Rights", name: "Women's Rights"},
  { id: 'Money in Politics', name: 'Money in Politics'},
]

export default optOutCategories