import React, { useState } from 'react'
import { useLogin, useNotify, useRedirect, useStore } from 'react-admin'
import { Button, Card, CardContent, FormGroup, Switch, TextField } from '@mui/material';

export const Login = () => {
  const [ baseURL ] = useStore('baseUrl', process.env.API_URL || window.location.origin)
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ totp, setTotp ] = useState('')
  const [ mode, setMode ] = useState('userpass')
  const [ trust, setTrust ] = useState(false)
  const login = useLogin()
  const notify = useNotify()
  const redirect = useRedirect()

  const handleSubmit = async (e) => {
    e.preventDefault()
    login({username: email, password})
    .catch(err => {
      if (err.message === 'totp_required') {
        setMode('totp')
      } else {
        notify(err.message)
      }
    })
  }

  const handleSubmitCode = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(`${baseURL}/auth/verify2FA.json`, {
        method: 'POST',
        body: `{"code": "${totp}", "saveTrust": ${trust}}`,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (response.status >= 300) {
        const text = await response.text()
        throw new Error(text)
      }
      const data = await response.json()
      const {firstName, lastName, token, expiresAt, isOwner} = data
      localStorage.setItem('email', email)
      localStorage.setItem('jwt', token)
      localStorage.setItem('name', `${firstName} ${lastName}`)
      localStorage.setItem('expires', expiresAt)
      localStorage.setItem('isOwner', isOwner)
      localStorage.setItem('has2fa', true)
      redirect('/')
    } catch (e) {
      console.error(`Failed to login: ${e}`)
    }
  }

  const handleEmail = (e) => {
    setEmail(e.target.value)
    return true
  }

  const handlePassword = (e) => {
    setPassword(e.target.value)
    return true
  }

  const handleTOTP = (e) => {
    setTotp(e.target.value)
    return true
  }

  const handleChangeTrust = (e) => {
    setTrust(e.target.checked)
    return true
  }

  if (mode === 'userpass') {
    return (
      <div className='loginPage'>
        <FormGroup>
          <Card className='login' sx={{backgroundColor: '#00000000'}}>
            <CardContent className='loginContent' sx={{backgroundColor: '#ffffffee'}}>
                <TextField
                  label='Enter your email address'
                  id='email'
                  value={email}
                  onChange={handleEmail} /><br
                />
                <TextField
                  label='Enter your password'
                  type='password' id='password'
                  value={password}
                  onChange={handlePassword} />
                <br />
                <Button
                  type='submit'
                  variant='outlined'
                  onClick={handleSubmit}>Login
                </Button>
                <br />
                <a href='/#/reset'>Forgot password?</a>
            </CardContent>
          </Card>
        </FormGroup>
      </div>
    )
  } else {
    return (
      <div className='loginPage'>
        <FormGroup>
          <Card className='totp' sx={{backgroundColor: '#00000000', flex: 1}}>
            <CardContent className='loginContent' sx={{backgroundColor: '#ffffffee', flex: 1}}>
                <TextField
                  label='Enter the code from your authentication app'
                  id='totp'
                  value={totp}
                  onChange={handleTOTP}
                  fullWidth />
                <Switch
                  id="saveTrust"
                  checked={trust}
                  onChange={handleChangeTrust}
                /> Trust this browser for 30 days<br />
                <Button
                  type='submit'
                  variant='outlined'
                  onClick={handleSubmitCode}>Login
                </Button>
            </CardContent>
          </Card>
        </FormGroup>
      </div>
    )
  }
}
