
import * as React from "react";
import { useRecordContext } from 'react-admin';
import { Link } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

const LinkIconUrlField = ({ source }) => {
  const record = useRecordContext();
  return record ? (
    <Link href={record[source]} target="_blank" sx={{ textDecoration: 'none' }}>
      <LaunchIcon sx={{ width: '0.66em', height: '0.66em', paddingLeft: 2 }} />
    </Link>
  ) : null;
}

export default LinkIconUrlField;