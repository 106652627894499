import React, { Fragment } from "react";
import {
  ArrayInput,
  BooleanField,
  Datagrid,
  List,
  TextField,
  TextInput,
  Pagination,
  Show,
  SimpleShowLayout,
  ArrayField,
  SingleFieldList,
  ShowButton,
  Edit,
  EditButton,
  SimpleForm,
  SimpleFormIterator,
  BooleanInput,
  ReferenceArrayField,
  ChipField,
  SelectInput
} from 'react-admin';
import {
  MakeActiveButton,
  DeactivateButton,
  MakeFeaturedButton,
  UnfeatureButton,
  //NotifyButton,
} from "./utility/BulkActiveFeatured";
// import { BulkNotifyButton } from "./utility/BulkNotifyButton";
import NotificationSelectInput from "./NotificationSelectInput";

import LinkIconUrlField from "./utility/LinkIconUrlField";
import { QuickFilter }  from "./utility/Quickfilter";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { ClockCircleOutlined } from '@ant-design/icons'
// import { OutletTextField } from "./OutletTextField";
import { FeedTextField } from "./utility/FeedTextField";
import { ShowImage } from "./utility/ShowImage";
import Typography from '@mui/material/Typography';
import { CategoryChip } from "./utility/categoryChip";
import { CustomCategoryChip } from "./utility/customCategoryChip";
import optOutCategories from "./utility/optOutCategories";

// import { CustomCategoryChip } from "./utility/customCategoryChip";

const MediaBulkActionButtons = props => (
  <Fragment>
      <MakeActiveButton />
      <DeactivateButton />
      <MakeFeaturedButton />
      <UnfeatureButton />
  </Fragment>
);

const mediaFilters = [
  <TextInput source="text" label="Search" alwaysOn />,
  <QuickFilter source="active" label="Active" defaultValue={true} />,
  <QuickFilter source="carousel" label="Featured" defaultValue={true} />
]

const MediaPagination = props =>
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const MediaList = () => (
  <List
    filters={mediaFilters}
    pagination={<MediaPagination/>}
    perPage={50}
    sort={{field: 'publicationDateISO', order: 'DESC'}}
  >
    <Datagrid bulkActionButtons={<MediaBulkActionButtons />} >
    <BooleanField
        source="active"
        label="Active"
        TrueIcon={DoneOutlineIcon}
        FalseIcon={ClockCircleOutlined}
        sx={{ color: 'green' }}
        sortable={false}
      />
      <BooleanField
        source="carousel"
        label="Featured"
        TrueIcon={DoneOutlineIcon}
        FalseIcon={ClockCircleOutlined}
        sx={{ color: 'blue' }}
        sortable={false}
      />
      <BooleanField
        source="notify"
        label="Notification"
        TrueIcon={DoneOutlineIcon}
        FalseIcon={ClockCircleOutlined}
        sx={{ color: 'blue' }}
        sortable={false}
      />
      <ShowImage />
      <TextField source="title" sortable={false} />
      <FeedTextField label='Feed' />
      <TextField source="Feed.format" label="Format" sortable={false} />
      <TextField source="publicationDateParsed" label="Published" sortable={false} />
      <EditButton />
      <ShowButton />
      <LinkIconUrlField source="url" label="Link" sortable={false} />
    </Datagrid>
  </List>
)

export const ShowMedia = () => (
  <Show title="Media">
    <SimpleShowLayout >
      <TextField source="id" label="ID" />
      <TextField source="title" />
      <TextField source="Feed.format" label="Format"  />
      <TextField source="Feed.Outlet.outletName" label="Outlet" />
      <TextField source="publicationDateParsed" label="Published"  />
      <TextField source="publicationDateISO" label="Published (ISO)" />
      <ReferenceArrayField label="Creators" reference="creators" source="CreatorIDs">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ArrayField source="customCategories" label="Opt-Out Categories">
        <SingleFieldList linkType={false}>
          <CustomCategoryChip />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="categories" label="RSS Categories">
        <SingleFieldList linkType={false}>
          <CategoryChip />
        </SingleFieldList>
      </ArrayField>
      <LinkIconUrlField source="url" label="Link"  />
    </SimpleShowLayout>
  </Show>
)

const validateMediaEdit = (values) => {
  const errors = {}
  if(!values.active && values.carousel) {
    errors.active = "Error: Media must be active to be featured"
  }
  return errors
}


export const MediaEdit = () => {
  return (
    <Edit title="Media">
      <SimpleForm validate={validateMediaEdit} sx={{ maxWidth: 800 }}>
        <Typography variant="h6" gutterBottom>
          Edit Media Information
        </Typography>
        <TextInput source="title" fullWidth />
        <TextInput source="publicationDateParsed" label="Published" fullWidth/>
        <TextInput source="publicationDateISO" label="Published (ISO)" fullWidth/>
        <BooleanInput source="active" label="Active" />
        <BooleanInput source="carousel" label="Featured"  />
        <Typography variant="h8" gutterBottom>
          Send Notifiction Now 
        </Typography>
        <NotificationSelectInput source="notifyTopic" label="Category" resource="media" />
        <ArrayInput source="customCategories" label="Opt-Out Categories">
          <SimpleFormIterator inline >
            <SelectInput source="name" label="Opt-Out Category" choices={optOutCategories} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="categories" label="RSS Categories">
          <SimpleFormIterator inline disableAdd>
            <TextInput source="name" label="RSS Category" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}

// <TextField source="publicationDateISO" label="Published (ISO)" sx={{ fontSize: 12 }} />