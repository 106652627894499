import React, { Fragment } from "react";
import {
  BooleanField,
  BooleanInput,
  BulkDeleteButton,
  Button,
  Create,
  Edit,
  EditButton,
  Datagrid,
  EmailField,
  List,
  SimpleForm,
  TextField,
  TextInput,
  useListContext,
  useRecordContext,
  useStore,
} from 'react-admin';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { AddCircle } from '@mui/icons-material'


export const ResetPasswordButton = () => {
  const list = useListContext()
  const [ baseURL ] = useStore('baseUrl', process.env.API_URL || window.location.origin)

  return (<Button
    label="Reset Password"
    onClick = {() => requestReset(baseURL, list.selectedIds)}
  ><AddCircle /></Button>
)};

export const Reset2FAButton = () => {
  const list = useListContext()
  const [ baseURL ] = useStore('baseUrl', process.env.API_URL || window.location.origin)

  return (<Button
    label="Reset 2FA"
    onClick = {() => requestReset2fa(baseURL, list.selectedIds)}
  ><AddCircle /></Button>
)};

const BulkActionButtons = (props) => (
  <Fragment>
    <ResetPasswordButton />
    <Reset2FAButton />
    <BulkDeleteButton />
  </Fragment>
)

const requestReset = async (baseURL, selectedIds) => {
  const url = `${baseURL}/auth/requestBulkReset.json`
  try {
    await fetch(url, {
      method: 'POST',
      body: `{"ids": ${JSON.stringify(selectedIds)}}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  } catch (err) {
    console.error(err)
  }
}

const requestReset2fa = async (baseURL, selectedIds) => {
  const url = `${baseURL}/auth/requestBulkReset2fa.json`
  try {
    await fetch(url, {
      method: 'POST',
      body: `{"ids": ${JSON.stringify(selectedIds)}}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  } catch (err) {
    console.error(err)
  }
}

const Separator = () => <Box pt="1em" />;

const AdminUserName = () => {
  const record = useRecordContext();
  return <span> {record ? `${record.firstName} ${record.lastName}` : ''} </span>
};

export const AdminUsersList = (props) => {
  return (
    <List>
      <Datagrid
        bulkActionButtons={<BulkActionButtons />}
      >
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <BooleanField source="isOwner" />
        <BooleanField source="password" label="Accepted" looseValue="true" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const validateUser = (values) => {
  // Source: https://regexpattern.com/email-address/
  const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const errors = {}
  if (values.firstName.length === 0 && values.lastName.length === 0) {
    errors.firstName = 'One of First Name or Last Name is required'
    errors.lastName = 'One of First Name or Last Name is required'
  }
  if (values.email.length === 0) {
    errors.email = 'Email address is required'
  } else if (!values.email.match(emailFormat)) {
    errors.email = 'Email address is not valid'
  }
  return errors
}

//  MUI tooltip needs to apply DOM event listeners to its child element
const WrappedBooleanInput = React.forwardRef(function MyComponent(props, ref) {
  return (
    <div {...props} ref={ref}>
      <BooleanInput source='isOwner' label='Owner Access?' />
    </div>
  );
});


export const AdminUsersCreate = (props) => {
  return (
    <Create>
      <SimpleForm validate={validateUser} sx={{ maxWidth: 800 }} >
        <Typography variant='h6'>
          Create a New User
        </Typography>
        <Separator />
        <TextInput source='firstName' label='First Name' fullWidth />
        <TextInput source='lastName' label='Last Name' fullWidth />
        <TextInput source='email' label='Email Address' fullWidth />
        <Tooltip title="Gives full access to all functions. Be careful when selecting">
          <WrappedBooleanInput />
        </Tooltip>
      </SimpleForm>
    </Create>
  )
}

export const AdminUsersEdit = () => {
  return (
   <Edit title={<AdminUserName />}>
    <SimpleForm validate={validateUser} sx={{ maxWidth: 800 }}>
        <Typography variant='h6'>
          Edit User
        </Typography>
        <Separator />
        <TextInput source='firstName' label='First Name' fullWidth />
        <TextInput source='lastName' label='Last Name' fullWidth />
        <TextInput source='email' label='Email Address' fullWidth />
        <Tooltip title="Gives full access to all functions. Be careful when selecting">
          <WrappedBooleanInput />
        </Tooltip>
      </SimpleForm>
   </Edit>
  )
}

