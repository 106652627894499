import { TextField, useRecordContext, useRedirect } from "react-admin";

export const MediaTextField = props => {
  const record = useRecordContext()
  const redirect = useRedirect()
  return (
    <TextField 
      source="title"
      sx={{ 
        textDecorationLine: 'underline',
        cursor: 'pointer' 
      }} 
      sortable={false}
      onClick={handleClickMedia.bind(this, record, redirect)} 
    />
  )
}

const handleClickMedia = (item, redirect) => {
  const mediaItem = item
  if (!mediaItem) return
  redirect?.('edit', 'media', mediaItem._id)
}