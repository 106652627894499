import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Title, useNotify, useRedirect, useStore } from 'react-admin'
import { FormGroup, Card, CardContent, TextField, Button } from '@mui/material';
import _ from 'lodash'

export const ResetPassword = () => {
  const [ password, setPassword ] = useState('')
  const [ passwordConfirm, setPasswordConfirm ] = useState('')
  const [ params ] = useSearchParams()
  const notify = useNotify()
  const redirect = useRedirect()
  const [ baseURL ] = useStore('baseUrl', process.env.API_URL || window.location.origin)

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (password !== passwordConfirm) {
      notify('Passwords don\'t match')
      return
    }
    try {
      await fetch(`${baseURL}/auth/doReset.json`, {
        method: 'POST',
        body: `{"token": "${params.get('token')}", "password": "${password}"}`,
        headers: {
          'Content-Type': 'application/json'
        },
      })
      redirect('/')
    } catch (err) {
      console.error(err)
      notify(err.message)
    }
  }

  const handlePassword = (e) => {
    e.preventDefault()
    if (e.target === document.getElementById('password')) {
      setPassword(e.target.value)
    } else {
      setPasswordConfirm(e.target.value)
    }
    return true
  }

  // useEffect(() => {
  //   if (password !== passwordConfirm) {
  //     notify('Passwords don\'t match')
  //     return
  //   }
  // }, [password, passwordConfirm])

  if (_.isEmpty(params.get('token'))) {
    return (
      <Card>
        <Title title="Reset Password" />
          <CardContent>
            Please follow the link in your email to reset your password
          </CardContent>
      </Card>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup sx={{flex: 1, width: '100%'}}>
        <Card>
          <CardContent>
            <TextField
              sx={{width: '50%'}}
              label="Password"
              type='password'
              id='password'
              value={password}
              onChange={handlePassword}
            /><br /><br />
            <TextField
              sx={{width: '50%'}}
              label="Password Confirm"
              type='password'
              id='passwordConfirm'
              value={passwordConfirm}
              onChange={handlePassword}
            />
            <br></br><br></br>
            <Button variant="contained" type='submit'>
              Set Password
            </Button>
          </CardContent>
        </Card>
      </FormGroup>
    </form>
  )
}

