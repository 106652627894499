import React, { useState } from 'react';
import { useUpdate, useNotify, useRefresh, useRecordContext } from 'react-admin';
import { SelectInput } from 'react-admin';
import notifyTopicChoices from './utility/notifyTopicChoices';

const NotificationSelectInput = ({ resource, source, label }) => {
  const record = useRecordContext();
  const [update, { isPending, error }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [selectedAction, setSelectedAction] = useState(record[source]);

  
  const handleChange = (event) => {
    const newValue = event.target.value
    console.log('Event:', event);
    console.log('New Value:', newValue);

    const updatedFields = {
      [source]: newValue,
      notify: true,
      needsNotify: 0
    }

    update(
      resource,
      { id: record.id, data: updatedFields, previousData: record },
      {
        onSuccess: () => {
          console.log('Notification successful');
          notify('Notificaiton successful', 'info');
          refresh();
        },
        onFailure: (error) =>{ 
          console.error('Notificaiton failed:', error);
          notify(`Error: ${error.message}`, 'warning')
        }

      }
    )
    
    if (error) { return <p>ERROR</p>; }
    console.log(`Error: ${error}`)

    setSelectedAction(newValue);
  }

  return (
    <SelectInput 
      source={source}
      label={label}
      choices={notifyTopicChoices}
      onChange={handleChange}
      value={selectedAction}
      disabled={isPending}
    />
  )

};

export default NotificationSelectInput;
