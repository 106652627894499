import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNotify, useRedirect, useStore } from 'react-admin'
import { FormGroup, Card, CardContent, TextField, Button } from '@mui/material';

export const RequestReset = () => {
  const [ params ] = useSearchParams()
  const [ email, setEmail ] = useState(params.get('email') || '')
  const redirect = useRedirect()
  const [ baseURL ] = useStore('baseUrl', process.env.API_URL || window.location.origin)
  const notify = useNotify()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const url = `${baseURL}/auth/requestReset.json`
    try {
      await fetch(url, {
        method: 'POST',
        body: `{"email": "${email}"}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      redirect('/')
    } catch (err) {
      console.error(err)
      notify(err.message)
    }
  }

  const handleEmail = (e) => {
    setEmail(e.target.value)
    return true
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup sx={{flex: 1, width: '100%'}}>
        <Card>
          <CardContent>
            <TextField
              sx={{width: '50%'}}
              label="Email"
              type='email'
              id='email'
              value={email}
              onChange={handleEmail}
            />
            <br></br><br></br>
            <Button variant="contained" type='submit'>
              Request Reset
            </Button>
          </CardContent>
        </Card>
      </FormGroup>
    </form>
  )
}

/*

  <form onSubmit={handleSubmit}>
    <br></br>
      Enter Your Email Address:
    <br></br>
    <br></br>
    <input
      name="email"
      type="email"
      value={email}
      onChange={handleEmail}
    />
    <br></br><br></br>
    <button type='submit'>
      Request Reset
    </button>
  </form>


*/